@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #fafafa;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
  line-height: 1.5rem !important;
}

.App {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 100%;
}

.page {
  width: 100%;
  height: 100%;
}

.bounce-in {
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
  -webkit-animation-name: bounce-in;
          animation-name: bounce-in;
}

@-webkit-keyframes bounce-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.98) translate3d(0, 0, 0);
            transform: scale(0.98) translate3d(0, 0, 0);
  }
  20% {
    opacity: 0.9;
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
  }
  45% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.98) translate3d(0, 0, 0);
            transform: scale(0.98) translate3d(0, 0, 0);
  }
  20% {
    opacity: 0.9;
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
  }
  45% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }
}

.fade-in {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: fade-in;
          animation-name: fade-in;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.99) translate3d(0, 0, 0);
            transform: scale(0.99) translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.99) translate3d(0, 0, 0);
            transform: scale(0.99) translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }
}

.ant-form-item-extra {
  font-size: x-small;
}

