.App {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.page {
  width: 100%;
  height: 100%;
}

.bounce-in {
  animation-duration: 0.6s;
  animation-name: bounce-in;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.98) translate3d(0, 0, 0);
  }
  20% {
    opacity: 0.9;
    transform: scale(1.01);
  }
  45% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

.fade-in {
  animation-duration: 0.3s;
  animation-name: fade-in;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.99) translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

.ant-form-item-extra {
  font-size: x-small;
}
